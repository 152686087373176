<template>
  <div class="radio-button-container">
    <h6>{{ title }}</h6>
    <InputRadioItem
        v-for="(item, index) in items"
        :key="index"
        :label="item.label"
        :option="item.option"
        :value="item.value" />
  </div>
</template>

<script>
import InputRadioItem from "@/components/InputRadioItem";
export default {
  name: "InputRadio",
  components: {InputRadioItem},
  props: {
    title: String,
    items: Array
  }
};
</script>

<style lang="scss">
.radio-button-container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  h6 {
    margin-bottom: 8px;
  }
}
</style>