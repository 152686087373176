<template>
  <div class="basic-settings grid two-columns">
    <div class="grid-item">
      <InputRadio title="Feuerwiderstandsfestigkeit" :items="fireResistanceItems" />
    </div>

    <div class="grid-item">
      <InputRadio title="Kragarm" :items="cantileverItems" />
      <img class="cantilever-image" :src="cantileverSVG" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import InputRadio from "@/components/InputRadio";
export default {
  name: "BasisSettings",
  components: {InputRadio},
  data() {
    return {
      fireResistanceItems: [
        { label: 'F90', option: 'fireResistance', value: 'f90' }
      ],
      cantileverItems: [
        { label: 'Ohne Kragarm', option: 'cantilever', value: false }
      ]
    }
  },
  computed: {
    ...mapGetters(['options']),
    cantileverSVG() {
      if(this.options.cantilever)
        return require('@/assets/span_with_cantilever.svg');
      else
        return require('@/assets/span_without_cantilever.svg');
    }
  },
  methods: {
    ...mapActions(['updateOption']),
  }
};
</script>

<style lang="scss">
.basic-settings {
  grid-column-gap: 32px;

  @media (max-width: $width-tablet) {
    grid-template-columns: 1fr !important;
    grid-row-gap: 24px;
  }

  .cantilever-image {
    width: 100%;
    height: auto;
    margin-top: 16px;
  }
}
</style>