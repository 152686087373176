<template>
  <div class="radio-button-item">
    <input
        type="radio"
        :name="option"
        :id="value + option"
        :value="value"
        @change="updateOption({ option: option, value: value })"
        :checked="options[option] === value">
    <label :for="value + option">{{ label }}</label>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "InputRadioItem",
  props: {
    label: String,
    option: String,
    value: null
  },
  computed: {
    ...mapGetters(['options'])
  },
  methods: {
    ...mapActions(['updateOption'])
  }
};
</script>

<style lang="scss">
.radio-button-item {
  display: flex;
  align-items: center;

  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    display: inline-block;
    width: 12px;
    height: 12px;
    padding: 2px;
    margin: 0;

    background-clip: content-box;
    border: 1px solid #bbbbbb;
    background-color: transparent;
    border-radius: 50%;
  }

  input[type="radio"]:checked {
    border: 1px solid #E3000B;
    background-color: #E3000B;
  }

  label {
    margin-left: 8px;

    font-family: futura-pt-book, sans-serif;
    font-size: 0.762rem;

    @media (max-width: $width-tablet) {
      font-size: 1rem;
    }
  }
}
</style>